import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus'
import locale from "element-plus/lib/locale/lang/zh-cn";
import 'element-plus/dist/index.css'
import router from './router/index'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import './init.js'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(ElementPlus, {locale})
    .use(router)
    .mount('#app')
