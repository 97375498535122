import {createRouter, createWebHashHistory} from 'vue-router'

const router = createRouter({
    history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
    routes: [
        {
            path: '/',
            redirect: '/officialIndex'
        }
    ]
})

export default router


//pc端的路由
export const pcRoutes = [
    {
        path: '/',
        redirect: '/officialIndex'
    },
    {
        path: '/officialIndex',
        name: 'officialIndex',
        component: () => import('../pages/official/officialIndex.vue')
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../pages/home/index.vue')
    },

];
//移动端设备路由
export const mobileRoutes = [
    {
        path: '/',
        redirect: '/officialIndex'
    },
    {
        path: '/officialIndex',
        name: 'officialIndex',
        component: () => import('../pages/official/mobileIndex.vue')
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../pages/home/mobileIndex.vue')
    },

];

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}